import { ref } from 'vue'
import { setLanguageFile, trans as ai18nTrans } from '@clickbar/ai18n'

import type { Plugin } from 'vue'
import { setLanguageFile as setTuiLanguageFile } from '@clickbar/tailwindui-vue'

type MessagesInterface = Record<string, string>

export const definedLanguages = {
  de: 'Deutsch',
  en: 'English',
  pl: 'Polski',
  cs: 'Čeština',
  fr: 'Français',
  nl: 'Nederlands',
  uk: 'Українська',
  gsw: 'Schwiizerdütsch',
  ja: '日本語',
  it: 'Italiano',
  es: 'Español',
  pt: 'Português',
  ...(import.meta.env.DEV ? { empty: 'Empty' } : {}),
} satisfies Record<string, string>
const fallbackLanguage = 'en' satisfies keyof typeof definedLanguages

const isServer = typeof window === 'undefined'

/**
 * Stores the current options.
 */
const activeLanguage = ref(getDefaultLanguage())

/**
 * Stores the loaded languages.
 */
const loadedLanguageFiles = new Map<string, MessagesInterface>()

/**
 * The active messages to use.
 */
const activeMessages = ref<MessagesInterface>({})

watchSyncEffect(() => {
  setLanguageFile(activeLanguage.value, activeMessages.value)
})

export function trans(
  key: Parameters<typeof ai18nTrans>[0],
  replacements?: Parameters<typeof ai18nTrans>[1],
) {
  // Make sure these are reactive dependencies:
  activeLanguage.value
  activeMessages.value

  return ai18nTrans(key, replacements)
}

function getDefaultLanguage(): string {
  return !isServer && document.documentElement.lang
    ? document.documentElement.lang.replace('-', '_')
    : fallbackLanguage
}

/**
 * Checks if the language is loaded.
 */
export function isLoaded(lang: string): boolean {
  lang = lang.replace('-', '_')

  return loadedLanguageFiles.has(lang)
}

async function loadLanguageFile(lang: string) {
  if (lang === 'empty') {
    return {}
  }

  const file = await import(`../../../../lang/${lang}.json`)
  return file.default as MessagesInterface
}

/**
 * Returns the current active language.
 */
export function getActiveLanguage(): string {
  return activeLanguage.value
}

export async function setLanguage(lang = activeLanguage.value) {
  if (!isServer) {
    // When setting the HTML lang attribute, hyphen must be use instead of underscore.
    document.documentElement.setAttribute('lang', lang.replace('_', '-'))
  }

  if (!loadedLanguageFiles.has(lang)) {
    loadedLanguageFiles.set(lang, await loadLanguageFile(lang))
  }

  activeLanguage.value = lang
  activeMessages.value = loadedLanguageFiles.get(lang)!

  setTuiLanguageFile(activeMessages.value)
}

export async function loadActiveLanguageFile() {
  const lang = activeLanguage.value
  const languageFile = await loadLanguageFile(lang)

  loadedLanguageFiles.set(lang, languageFile)
}

/**
 * The Vue Plugin. to be used on your Vue app like this: `app.use(i18nVue)`
 */
export const i18nVue: Plugin = {
  install: (app) => {
    ;(app.config.globalProperties as any).$t = (
      key: Parameters<typeof ai18nTrans>[0],
      replacements?: Parameters<typeof ai18nTrans>[1],
    ) => trans(key, replacements)

    setLanguage(activeLanguage.value)
  },
}
