import { Inertia } from '@inertiajs/inertia'
import { usePage } from '@inertiajs/inertia-vue3'
import { cloneDeep } from 'lodash-es'
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'

import { getActiveLanguage } from '@/Plugins/i18n'

import type { Tenant } from '@/Models/Tenant'
import type { GlobalEvent, PageProps } from '@inertiajs/inertia'
import type { Ref } from 'vue'

export function useEnvironment() {
  const page = usePage()
  return computed(() => page.props.value.environment)
}

export function useErrors() {
  const page = usePage()
  return computed(() => page.props.value.errors)
}

export function useIsEnvironment(environment: string) {
  return computed(() => useEnvironment().value === environment)
}

export function useUser() {
  const page = usePage()
  // TODO: Think about, if we need to use this undefined cast in every function here
  // -> because when being used in eg. composables, this is executed before the
  //    page props are ready and thus can be undefined...
  // Otherwise we could also remove usages from other composables and only allow
  // the usage in vue components
  return computed(() => (page.props.value as PageProps | undefined)?.auth.user)
}

export function mustUseUser() {
  const page = usePage()
  return computed(() => {
    const user = (page.props.value as PageProps | undefined)?.auth.user
    if (!user) {
      throw new Error('User is not logged in. If this is an expected state, use useUser() instead.')
    }
    return user
  })
}

export function useLanguage() {
  const page = usePage()
  return computed(
    () => (page.props.value as PageProps | undefined)?.auth.user?.locale ?? getActiveLanguage(),
  )
}

export function useScope() {
  const page = usePage()
  return computed(() => (page.props.value as PageProps | undefined)?.auth.user?.scope ?? 'client')
}

export function useFlash() {
  const page = usePage()
  return computed(() => page.props.value.flash)
}

export function useClient() {
  const page = usePage()
  return computed(() => page.props.value?.auth.client ?? null)
}

export function useAgency() {
  const page = usePage()
  return computed(() => page.props.value.auth.agency)
}

export function useTenant() {
  const page = usePage()
  return computed<Tenant | null>(() => page.props.value.auth.agency ?? page.props.value.auth.client)
}

export function useIsAdmin() {
  const user = useUser()
  const tenant = useTenant()

  if (!user.value || !tenant.value) {
    return false
  }

  return user.value.is_super_admin || tenant.value.pivot?.role === 'admin'
}

export function useIsSuperAdmin() {
  const user = useUser()
  return user.value?.is_super_admin ?? false
}

// TODO: this doesn't work on back events!
// TODO: find a better way to detect route leaves!
export function useBeforeLeave(handler: (event: GlobalEvent<'before'>) => boolean) {
  onMounted(function () {
    const detach = Inertia.on('before', function (event) {
      if (
        event.detail.visit.method === 'get' &&
        event.detail.visit.url.href !== document.location.href
      ) {
        return handler(event)
      }
      return true
    })
    onBeforeUnmount(detach)
  })
}

export function useReactiveForm<T>(property: Ref<T>): Ref<T> {
  const form = ref(cloneDeep(property.value)) as Ref<T>
  watch(property, (value) => {
    form.value = cloneDeep(value)
  })
  return form
}

export function useReactiveFormModal<T, E>(
  property: Ref<T>,
  show: Ref<boolean>,
  transformer: (data: T) => E,
): Ref<E> {
  const form = ref(transformer(property.value)) as Ref<E>
  watch(show, () => {
    form.value = transformer(property.value)
  })
  return form
}
